import https from '@/router/https'

//支付宝支付
export function Paymenttype(param) {
  return https.fetchPost('/mallapp/pay/appAlipay', 
  param);
}
// 微信支付
export function submitWxpay(param) {
  return https.fetchPost('/mallapp/orderinfo/unifiedOrder', param);
}