<template>
  <div class="page">
    <van-nav-bar style="color: #000000" title="提交订单" left-arrow @click-left="$router.back(-1)" />
    <div class="content">
    <van-cell-group v-for="(item,index) in placeOrderdeal" :key="index">
      <van-cell :title="item.name" :value="'￥'+item.salesPrice" />
      <van-cell title="数量">

        <van-stepper v-model="valuer" />
      </van-cell>
      <van-cell title="小计" :value="'￥'+ (item.salesPrice * valuer).toFixed(2)" />
        <van-cell title="抵用券" is-link :value=" '-￥'+(list[num].reduceAmount||totalPrice*(10-list[num].discount)/10) " @click="showList=true" v-if="num!=null" />
      <van-cell title="抵用券" is-link :value="'有'+length+'张优惠券可用'" @click="showList=true" v-if="num==null&&length" />
      <van-cell title="抵用券" is-link :value="'暂无可用优惠券'" @click="showList=true" v-if="!length" />

       <van-cell title="订单总价" :value="'￥'+ ( (totalPrice-(list[num].reduceAmount||totalPrice*(10-list[num].discount)/10))).toFixed(2) " v-if="num!=null" />
      <van-cell title="订单总价" :value="'￥'+totalPrice.toFixed(2) " v-if="num==null" />
    </van-cell-group>


    
    
 <!-- :coupons="coupons"
  :chosen-coupon="chosenCoupon" -->
<!-- 优惠券列表 -->

 <van-radio-group v-model="paymentWay" style="margin-top: 1rem">
      <van-cell-group>
        <!-- <van-cell clickable @click="radio = '1'">
          <template #title>
            <img class="img" src="../../assets/img/alipay-icon.png" alt />
            <span class="custom-title">钱包余额</span>
          </template>
          <template #right-icon>
            <van-radio name="1" />
          </template>
        </van-cell>-->

        <van-cell clickable v-model="radio">
          <template #title @click="radio == '2'">
            <img class="img" src="../../assets/img/alipay-icon.png" alt />
            <span class="custom-title">支付宝</span>
          </template>
          <template #right-icon>
            <van-radio name="2" />
          </template>
        </van-cell>

        <van-cell clickable v-model="radio">
          <template #title @click="radio == '1'">
            <img class="img" src="../../assets/img/weChat-icon.png" alt />
            <span class="custom-title">微信</span>
          </template>
          <template #right-icon>
            <van-radio name="1" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
     

   

   

    <van-cell-group style="margin-top: 1rem">
      <van-cell title="购买须知" />
      <van-cell>
        <p>·退款规则：随时退，过期退</p>
        <p>·售卖规则：本着为未成年人的健康成长创造良好的社会环境原则，本产品依法不对未成年人售卖</p>
      </van-cell>
    </van-cell-group>

    <div style="height: 50px"></div>
    <van-submit-bar button-text="立即支付" @submit="DealplaceOrdertype">
      <template #default v-if="num!=null">{{'￥'+ ((totalPrice-(list[num].reduceAmount||totalPrice*(10-list[num].discount)/10))).toFixed(2)}}</template>
      <template #default v-else>{{'￥'+ totalPrice.toFixed(2)}}</template>

    </van-submit-bar>
  </div>
  <van-action-sheet v-model="showList" title="优惠券" >


<div class="wrap">
               
<div class="main">
  <div class="img-box" v-if="list.length === 0">
              
        <img src="../../assets/img/zanwu.png" alt="" />
          
         
          <div>暂无可用优惠券</div>
          
       
        </div>
                      <div class="box" v-if="list.length">
                        
                        <div class="item" v-for="(item ,index) in list " :key="item.ids" >

                                <div class="left">

                                      <div class="title" v-if="item.type=='1'">
                                        
                                        {{item.reduceAmount}}<span>元</span>

                                      </div>
                                       <div class="title" v-else align="center">
                                        
                                        {{item.discount}}<span>折</span>

                                      </div>

                                      <div class="rule">

                                        满{{item.premiseAmount}}元可用

                                      </div>
                                </div>


                                <div class="right"> 

                                      <div class="name">

                                        {{item.name}}

                                      </div>

                                      <div class="date">

                                        有效期至{{item.validEndTime.slice(0,10)}}
                                      </div>
                                </div>

                                <div class="icon">
                                  <img src="../../assets/img/checkout.png" v-show="num!==index&&item.premiseAmount<=totalPrice" alt="" @click="num=index" >
                                  <img src="../../assets/maker/check_in.png" v-show="num===index&&item.premiseAmount<=totalPrice" alt="" @click="num=null">

                                </div>
                                <img src="../../assets/bukeyong.png"  v-if="item.premiseAmount>totalPrice"  class="close" alt="">

                        </div>



                      </div>

              </div>

              <div class="footer">
                  <div class="btn" @click="showList=false">
                    确定
                  </div>
              </div>
              </div>
</van-action-sheet>
  </div>
</template>

<script>
import { DealplaceOrder } from "@/api/placeOrder/placeOrder";
import { submitWxpay } from "@/api/payment/payment";
import { aliPay} from '../../api/AliPay/index'
import { Toast } from "vant";
import { getCouponList } from "../../api/cop/index";

export default {
  data() {
    return {
      valuer: 1,
      radio: "",
      placeOrderdeal: [],
      skus: [],
      paymentWay: "2",
      type: '',
      data:'',
      coupon:null,
      value:'请选择优惠券',
      showList:false,
      list:[],
      num:null
    };
  },
  mounted() {
    window.closePage = this.closePage;
     if(this.$route.query.session!=null)
      {
         this.session=this.$route.query.session
              //设置session
         this.$store.commit('setSession', this.session)
      }
  },
  activated(){ 
     try{
    this.coupon= JSON.parse(sessionStorage.getItem('coupon')) 
      sessionStorage.removeItem('coupon')
    }catch{ 
     //
   }
  },
  watch:{ 
      valuer(){ 

        if(this.num!=null&&this.totalPrice<this.list[this.num].premiseAmount){ 
          this.num=null
        }
      }

  },
  created() {
    this.setRem()
    // this.id = this.$route.query.id;
    this.skuId = this.$route.query.skuId;
    this.spuId = this.$route.query.spuId;
  

    this.placeOrderdeal = [
      {
        name: this.$route.query.name,
        salesPrice: this.$route.query.salesPrice,
      },
    ];
    if(this.$route.query.type) {
      this.type = this.$route.query.type
    }
getCouponList({shopId:this.$route.query.shopId,spuIds:this.$route.query.spuId}).then(res=>{ 
  if(res.data.code===0){ 
    this.list = res.data.data.records
  }
})
    
  },
  methods: {
    voucher() {
      this.$router.push({ 
        path: "Purchase" ,
        query:{ 
          shopId:this.$route.query.shopId,
          spuId:this.$route.query.spuId,
          price:this.totalPrice
        }


      });


    },
    onClickLeft() {
      Toast("返回");
    },

    DealplaceOrdertype() {
      let appType = "";
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        appType = "isIOS";
      }
      if (isAndroid) {
        appType = "isAndroid";
      }
      //立即支付

      let price=this.totalPrice
      if(this.num){ 

     price=  (price-(this.list[this.num].reduceAmount||price*(10-this.list[this.num].discount)/10))
      }
      let params = {
        paymentWay: this.paymentWay,
        deliveryWay: "0",
        paymentType: "1",
        appType: appType,
        delivery_way: "2",
        orderType: "0",
        skus: [
          {
            spuId: this.spuId,
            skuId: this.skuId,
            quantity: this.valuer,
            paymentPrice: this.num !=null ?  ( (this.totalPrice-(this.list[this.num].reduceAmount|| this.totalPrice*(10- this.list[ this.num].discount)/10))).toFixed(2) : price,
            couponUserId:this.num !=null ?this.list[this.num].id:null,
            paymentCouponPrice: this.num != null ? (this.list[this.num].reduceAmount|| this.totalPrice*(10 - this.list[this.num].discount)/10).toFixed(2) : 0
            
          },
        ],
        type: this.type
      };
      DealplaceOrder(params).then((res) => {
       
          if(res.data.code == 0 ){ 

            // if( (this.num !=null ?  ( (this.totalPrice-(this.list[this.num].reduceAmount|| this.totalPrice*(10- this.list[ this.num].discount)/10))).toFixed(2) : price) == 0){ 

            //         this.$toast.success('下单成功')
            //         this.$goBack()
            //         return
            // }
           
            if(this.paymentWay == '2'){
       
          
                  let alipayparms = {
                              id:res.data.data[0].id,
                              pamentPrice: res.data.data[0].paymentPrice,
                  };
           
                  this.Paymenttype(alipayparms);

        }else {
         
                  let wxpayparams = {
                          id:res.data.data[0].id,
                          tradeType:'APP'
                  }
                  this.submitWxpay(wxpayparams);
          }

            
          }



       
        
        
      });
    },
    closePage() {
      //支付成功
      
     this.$goBack()
    },
   // 调原生支付宝支付
    Paymenttype(params) {
      aliPay(params).then((res) => {
        
          if(res.data.data==null){ 
             this.$toast.success('下单成功')
            this.$goBack()
            return
          }
      
           
        
        if (res.status === 200) {
          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

          if (isIOS) {
            window.webkit.messageHandlers.alipay.postMessage(res.data.data);
          }
          if (isAndroid) {
            window.android.alipay(res.data.data);
          }
        } 
      });
    },
    //调原生微信支付
   submitWxpay(params){
     submitWxpay(params).then((res) =>{

       if( res.data.data === null ){ 

            this.$toast.success('下单成功')
            this.$goBack()
            return
          }
       
       if(res.status === 200){
          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
           if (isIOS) {
            window.webkit.messageHandlers.wechatPay.postMessage(
          JSON.stringify(res.data.data)
        );
          }
          if (isAndroid) {
          window.android.wxpay(
          res.data.data.appId,
          res.data.data.partnerId,
          res.data.data.prepayId,
          res.data.data.nonceStr,
          res.data.data.timeStamp,
          res.data.data.packageValue,
          res.data.data.sign
        );
          }
       }
     })
   }
  },
  computed: {
    totalPrice() {
      let count = 0;
      this.placeOrderdeal.map((x) => {
        count += x.salesPrice * this.valuer;
      });
      // if(true) {
      //   count = count - 44
      // } else {
      //   count = count * 0.97
      // }
     
      return count;
    },
    length(){ 
        let count = 0 

        this.list.forEach(item => {
          if(item.premiseAmount <= this.totalPrice){ 
              count++
          }
        });

        return count
    }
  },
};
</script>

<style lang="less" >
.page{  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
}
.img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
}
.van-submit-bar__bar {
  display: flex;
  justify-content: space-between;
}
.van-action-sheet__content{ 
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.wrap{ 
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto; 
  min-height: 4rem;   
  background-color: white;

}
.main{ 
    
      flex: 1;
      overflow: auto;
      border-radius:.266667rem;

      .box{ 
        min-height: 10rem;
      border-radius:.266667rem;
         background-color: #f6f6f6;
         padding: .266667rem  .266667rem 1.5rem .266667rem;
        .item{ 
          position: relative;
              width: 100%;
              border-radius: .48rem;
              background-color: white;
              display: flex;
              padding: .266667rem .32rem;
              color: #999;
              font-size: 14px;
              box-sizing: border-box;
              margin-bottom: .266667rem;
                    .left{ 
                      width: 2.5rem;
                      height: 100%;
                      border-right: 1px dotted  #E0E0E0;
                      padding-top: .533333rem;
                      padding-right: .266667rem;
                      box-sizing: border-box;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                            .title{ 
                            color: #333;
                            font-size:30px ;
                            font-weight: 600;
                            text-align: center;
                            }
                            span{ 
                              font-weight: 400;
                              font-size: 14px;
                            }
                            .rule{ 
                              font-size: 12px;
                              margin-bottom: .426667rem;
                              text-align: center;
                            }
                       }

                      .right{ 

                        padding-top: .4rem;
                        padding-left: 0.5rem;

                            .name{ 
                              margin-bottom: 20px;
                            }
                            flex: 1;
                      }
                      .icon{ 
                            img{ 
                                width: .666667rem;
                                height: .666667rem;
                            }

                          display: flex;
                          padding: 0 .533333rem;
                          box-sizing: border-box;
                          align-items: center;

                      }
                      .close{ 
                        position: absolute;
                        height:1.52rem ;
                        width: 1.52rem;
                        right: 0;
                        top: 0;
                      }
                      

        }
      }
    }
   
     .footer{ 
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      background-color: white;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      // position: fixed;
      // width: 100%;
      // bottom: 0;
      // left: 0;
      .btn{ 
        width:344px ;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
        background-image: linear-gradient(to right,#FF7200,#FF3C00);
        margin: 10px auto;
        color: white;
      }
    }
    .img-box{
      background-color: white;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
     flex-direction: column;
     div{
       padding: .266667rem  0;
       color: #aaa;
     }
    }
</style>